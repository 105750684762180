.reorderItemCard {
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  text-align: left;
  padding: 0;
  height: 136px;
  min-height: 136px;
  width: 100%;
  max-width: 612px;
  overflow: hidden;

  // Default to "subtle" for border stroke, border radius, and drop shadow
  border: 0.75px solid var(--ds-border-default, #e0e0e0);
  &.borderStrokeNone {
    border: none;
  }
  &.borderStrokeExaggerated {
    border: 2px solid var(--ds-border-default, #e0e0e0);
  }

  border-radius: 8px;
  &.roundedCornerNone {
    border-radius: 1px;
  }
  &.roundedCornerExaggerated {
    border-radius: 20px;
  }

  box-shadow: 0 2px 4px 0 var(--ds-surface-shadow, #e0e0e0), 0 0 6px 0 #00000005;
  &.dropShadowNone {
    box-shadow: none;
  }
  &.dropShadowExaggerated {
    box-shadow: 0 4px 8px 0 var(--ds-surface-shadow, #0000000F), 0 0 4px 0 #0000000A;
  }

  &:not(:disabled):hover {
    box-shadow: 0 0 24px var(--ds-surface-shadow, rgba(0, 0, 0, 0.2));
    cursor: pointer;
  }

  &:disabled {
    cursor: default;
  }

  .itemInfo {
    padding: 16px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .itemText {
      font-size: 16px;
      font-weight: 500;
      @extend %line-clamp-1;
    }

    .modifierText {
      font-size: 14px;
      @extend %line-clamp-2;
    }

    .itemPrice {
      font-size: 14px;
      margin-top: auto;
    }
  }

  .itemImage {
    aspect-ratio: 1 / 1;
  }

  .seeMore {
    width: 100%;
    padding: 16px;
    gap: 16px;
    font-size: 16px;
    color: $ds-text-action;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
}
